html,
body {
  margin: 0;
  height: 100%;
  overflow: auto;
  font-family: GT-Walsheim-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

#root {
  height: 100%;
  background-color: #000;
  color: white;
  overflow: auto;
}

@keyframes ldio-1apunrqug7w-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(36px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(36px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}

@keyframes ldio-1apunrqug7w {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(36px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.loadingio-spinner-dual-ball-230pzzbtg4e {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-1apunrqug7w {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-1apunrqug7w div {
  box-sizing: content-box;
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  top: 82px;
  left: 64px;
}

.ldio-8dz1mlkc5ua div {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  transform: translate(43px, 43px) scale(1);
  background: #7d96ff;
  animation: ldio-8dz1mlkc5ua 1.4925373134328357s infinite
    cubic-bezier(0, 0.5, 0.5, 1);
  box-sizing: content-box;
}

.ldio-68e6sazggxo div {
  position: absolute;
  width: 68.48px;
  height: 68.48px;
  border-radius: 50%;
  top: 72.76px;
  left: 38.52px;
  box-sizing: content-box;
}

.ldio-1apunrqug7w div:nth-child(1) {
  background: #7d96ff;
  animation: ldio-1apunrqug7w 0.9345794392523364s linear infinite;
  animation-delay: -0.4672897196261682s;
}
.ldio-1apunrqug7w div:nth-child(2) {
  background: #fff;
  animation: ldio-1apunrqug7w 0.9345794392523364s linear infinite;
  animation-delay: 0s;
}
.ldio-1apunrqug7w div:nth-child(3) {
  background: #7d96ff;
  animation: ldio-1apunrqug7w-o 0.9345794392523364s linear infinite;
  animation-delay: -0.4672897196261682s;
}

.ldio-8dz1mlkc5ua div:nth-child(1) {
  background: #7d96ff;
  transform: translate(77px, 43px) scale(1);
  animation:
    ldio-8dz1mlkc5ua-r 0.3731343283582089s infinite cubic-bezier(0, 0.5, 0.5, 1),
    ldio-8dz1mlkc5ua-c 1.4925373134328357s infinite step-start;
}
.ldio-8dz1mlkc5ua div:nth-child(2) {
  animation-delay: -0.3731343283582089s;
  background: #7d96ff;
}
.ldio-8dz1mlkc5ua div:nth-child(3) {
  animation-delay: -0.7462686567164178s;
  background: #7d96ff;
}
.ldio-8dz1mlkc5ua div:nth-child(4) {
  animation-delay: -1.1194029850746268s;
  background: #7d96ff;
}
.ldio-8dz1mlkc5ua div:nth-child(5) {
  animation-delay: -1.4925373134328357s;
  background: #7d96ff;
}
.loadingio-spinner-ellipsis-afx9z4ishqt {
  width: 100px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-8dz1mlkc5ua {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

@keyframes ldio-8dz1mlkc5ua {
  0% {
    transform: translate(9px, 43px) scale(0);
  }
  25% {
    transform: translate(9px, 43px) scale(0);
  }
  50% {
    transform: translate(9px, 43px) scale(1);
  }
  75% {
    transform: translate(43px, 43px) scale(1);
  }
  100% {
    transform: translate(77px, 43px) scale(1);
  }
}

@keyframes ldio-8dz1mlkc5ua-r {
  0% {
    transform: translate(77px, 43px) scale(1);
  }
  100% {
    transform: translate(77px, 43px) scale(0);
  }
}

@keyframes ldio-8dz1mlkc5ua-c {
  0% {
    background: #7d96ff;
  }
  25% {
    background: #7d96ff;
  }
  50% {
    background: #7d96ff;
  }
  75% {
    background: #7d96ff;
  }
  100% {
    background: #7d96ff;
  }
}

@keyframes ldio-68e6sazggxo-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(68.48px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(68.48px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}

@keyframes ldio-68e6sazggxo {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(68.48px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.ldio-68e6sazggxo div:nth-child(1) {
  background: #7d96ff;
  animation: ldio-68e6sazggxo 0.9900990099009901s linear infinite;
  animation-delay: -0.49504950495049505s;
}
.ldio-68e6sazggxo div:nth-child(2) {
  background: #3c53b4;
  animation: ldio-68e6sazggxo 0.9900990099009901s linear infinite;
  animation-delay: 0s;
}
.ldio-68e6sazggxo div:nth-child(3) {
  background: #7d96ff;
  animation: ldio-68e6sazggxo-o 0.9900990099009901s linear infinite;
  animation-delay: -0.49504950495049505s;
}
.loadingio-spinner-dual-ball-zbui1g3zyl {
  width: 214px;
  height: 214px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-68e6sazggxo {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.datepicker-modal {
  z-index: 1001 !important;
}

.ant-select-dropdown {
  background: rgb(26, 26, 26);
}

.ant-select-item {
  color: white !important;
}

.ant-select-item-option-active {
  background: rgb(26, 26, 26) !important;
  color: white !important;
  font-size: 17px !important;
}

apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 48px;
  --apple-pay-button-border-radius: 24px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}

@keyframes ldio-1apunrqug7w-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(36px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(36px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}

@keyframes ldio-1apunrqug7w {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(36px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes ldio-68e6sazggxo-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(68.48px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(68.48px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}

@keyframes ldio-68e6sazggxo {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(68.48px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.disableMaxWidth {
  max-width: none !important;
}

/* BARCODE SCANNER */

/* adds the 4 corners */
.anim-box:before,
.anim-box:after,
.anim-box > :first-child:before,
.anim-box > :first-child:after {
  position: absolute;
  width: 10%;
  height: 15%;
  border-color: white;
  border-style: solid;
  content: ' ';
}

.dark-animBox:before,
.dark-animBox:after,
.dark-animBox > :first-child:before,
.dark-animBox > :first-child:after {
  border-color: black;
}

/* top left corner */
.anim-box:before {
  top: 0;
  left: 0;
  border-width: 2px 0 0 2px;
}

/* top right corner */
.anim-box:after {
  top: 0;
  right: 0;
  border-width: 2px 2px 0 0;
}

/* bottom right corner */
.anim-box > :first-child:before {
  bottom: 0;
  right: 0;
  border-width: 0 2px 2px 0;
}

/* bottom left corner */
.anim-box > :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 2px 2px;
}

@keyframes scan {
  0% {
    box-shadow: 0px 0px 8px 10px #c71b327d;
    top: 50%;
  }
  25% {
    box-shadow: 0px 6px 8px 10px #c71b327d;
    top: 5px;
  }
  75% {
    box-shadow: 0px -6px 8px 10px #c71b327d;
    top: 98%;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
