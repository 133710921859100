.ant-message {
  color: #000;
  font-family: GT-Walsheim-Regular, sans-serif;
}

.ant-message .ant-message-notice .ant-message-notice-content {
  font-family: GT-Walsheim-Regular, sans-serif;
}

.ant-notification > * > .ant-notification-notice {
  padding: 16px 10px 16px 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.ant-notification > * > .ant-notification-notice::after {
  content: '';
  width: 8px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.ant-notification > * > .ant-notification-notice-info::after {
  background-color: rgb(80, 102, 124);
}

.ant-notification
  > *
  > .ant-notification-notice-error
  > .ant-notification-notice-content
  > *
  > .ant-notification-notice-icon-error {
  color: rgb(241, 103, 4);
}

.ant-notification
  > *
  > .ant-notification-notice-info
  > .ant-notification-notice-content
  > *
  > .ant-notification-notice-icon-info {
  color: rgb(80, 102, 124);
}

.ant-notification
  > *
  > .ant-notification-notice
  > .ant-notification-notice-content
  > *
  > .ant-notification-notice-message {
  font-family: GT-Walsheim-Bold, sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
}

.ant-notification
  > *
  > .ant-notification-notice-error
  > .ant-notification-notice-content
  > *
  > .ant-notification-notice-message {
  color: rgb(241, 103, 4);
}

.ant-notification
  > *
  > .ant-notification-notice-info
  > .ant-notification-notice-content
  > *
  > .ant-notification-notice-message {
  color: rgb(80, 102, 124);
  font-family: GT-Walsheim-Regular, sans-serif;
}

.ant-notification
  > *
  > .ant-notification-notice
  > .ant-notification-notice-content
  > *
  > .ant-notification-notice-description {
  color: rgb(129, 129, 129);
  font-family: GT-Walsheim-Regular, sans-serif;
  font-size: 15px;
  line-height: 20px;
}

.ant-notification
  > *
  > .ant-notification-notice
  > .ant-notification-notice-close {
  color: rgb(27, 28, 30);
  font-size: 17px;
  right: 10px;
  top: 10px;
}

.ant-btn-primary[disabled] {
  font-family: GT-Walsheim-Bold, sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;

  background: rgb(160 200 255 / 36%);
  border: 1px solid transparent;
  color: #000;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #000;
}

.ant-drawer {
  z-index: 9999;
}

.ant-drawer-content-wrapper {
  padding: 40px 0 0 !important;
  box-shadow: none !important;
}

/* Remove autocomplete background color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

/* Date picker */
.ant-picker-dropdown .ant-picker-panel-container {
  width: 100%;
  font-family: GT-Walsheim-regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  background: #3d3d3d;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0px;
}
.ant-picker-dropdown .ant-picker-time-panel {
  width: 100%;
}
.ant-picker-dropdown .ant-picker-header {
  color: #fff;
}
.ant-picker-dropdown .ant-picker-header > button {
  color: #7d96ff;
}
.ant-picker-dropdown .ant-picker-content th {
  color: #fff;
}
.ant-picker-dropdown .ant-picker-content tbody > tr > td {
  color: #fff;
}
.ant-picker-dropdown .ant-picker-today-btn {
  color: #7d96ff;
}

/* Select dropdown */
.ant-select-dropdown {
  width: 100%;
  background: #3d3d3d;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff !important;
  border-radius: 0;
  padding: 0;
}

.rc-virtual-list-holder {
  padding: 0;
  width: 100%;
  background: #3d3d3d;
  border: none;
  color: #fff !important;
  border-radius: 0;
}

.ant-select-item-option {
  color: #fff !important;
  font-family: GT-Walsheim-Regular, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.ant-select-item-option.ant-select-item-option-active {
  font-family: GT-Walsheim-Bold, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  border: none;
  border-radius: 0;
  color: #000 !important;
  background: #7d96ff !important;
}

.ant-select-item-option.ant-select-item-option-selected {
  color: #000 !important;
  font-family: GT-Walsheim-Bold, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  background: #7d96ff !important;
  border: none;
  border-radius: 0;
}

/* Validation Errors */
.ant-picker-status-error {
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  background-color: transparent !important;
}

.ant-form-item-explain-error {
  color: #f16704 !important;
  background-color: transparent !important;
}

.ant-form-item-control-input-content > .ant-input-status-error {
  color: #f16704 !important;
  background-color: transparent !important;
}

.ant-switch {
  background-color: rgba(255, 255, 255, 0.5);
}

.ant-switch:hover:not(.ant-switch-disabled),
.ant-switch:focus:not(.ant-switch-disabled) {
  background-color: rgba(255, 255, 255, 0.5);
}

.ant-switch.ant-switch-checked {
  background-color: #7d96ff;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled),
.ant-switch.ant-switch-checked:focus:not(.ant-switch-disabled) {
  background-color: #7d96ff;
}

a {
  color: #7d96ff;
}
